import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Introduction from './components/Introduction';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';

function App() {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div class="content">
          <Introduction />
          <About />
          <Projects />
          <Skills />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
