import React from 'react';

const Introduction = () => {
  return (
    <div id="home" class="bio-section">
      <h1 class="name">
        <span class="long-dash"></span>
        <span class="name-text">Priscilla Kalilombe</span>
      </h1>

      <p class="intro-text">Hi, my name's Priscilla.<br /> I'm thrilled to have you here!</p>
    </div>
  )
}

export default Introduction;