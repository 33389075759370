
const Sidebar = () => {
  return (
    <div className="sidebar-wrapper">
      <div id="sidebar">
        <nav>
          <ul>
            <li className="home">
              <a href="#home">
                <span className="menu-item-icon"><i className="fal fa-house"></i></span>
                <span className="menu-item-text">Home</span>
              </a>
            </li>
            <li className="about-me">
              <a href="#about-me">
                <span className="menu-item-icon"><i className="fal fa-user"></i></span>
                <span className="menu-item-text">About me</span>
              </a>
            </li>
            <li className="my-work">
              <a href="#my-work">
                <span className="menu-item-icon"><i className="fal fa-laptop"></i></span>
                <span className="menu-item-text">My work</span>
              </a>
            </li>
            <li className="my-skills">
              <a href="#skills">
                <span className="menu-item-icon"><i className="fal fa-code"></i></span>
                <span className="menu-item-text">Skills</span>
              </a>
            </li>
          </ul>
        </nav>
        <div className="contact-info">
          <ul>
            <li className="contact-item-icon">
              <a href="https://github.com/priscilla-codes/" target="_blank" rel="noopener noreferrer">
              <img src="/images/github-icon.svg" />
              </a>
            </li>
            <li className="contact-item-icon">
              <a href="https://www.linkedin.com/in/priscilla-kalilombe/" target="_blank" rel="noopener noreferrer">
              <img src="/images/linkedin-icon.svg" />
              </a>
            </li>
            <li class="contact-item-icon">
              <a href="mailto:priscilla@afroic.com">
                <img src="/images/email-icon.svg" />
              </a>
            </li>  
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar