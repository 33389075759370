import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year using JavaScript

  return (
    <div className="footer-wrapper">
      <div className="footer">&copy; {currentYear} PriscillaCodes By Priscilla Kalilombe</div>
    </div>
  );
};

export default Footer;
