const technologies = [
  {
    category: 'Frontend',
    items: [
      { name: 'React', image: 'images/technologies/react.png' },
      { name: 'Redux', image: 'images/technologies/redux.png' },
      { name: 'JavaScript', image: 'images/technologies/javascript.png' },
      { name: 'jQuery', image: 'images/technologies/jquery.png' },
      { name: 'HTML', image: 'images/technologies/html.png' },
      { name: 'CSS', image: 'images/technologies/css.png' },
    ],
    fontAwesomeIcon: "fa-browser"
  },
  {
    category: 'Backend',
    items: [
      { name: 'Ruby', image: 'images/technologies/ruby.png' },
      { name: 'Ruby on Rails', image: 'images/technologies/ruby-on-rails.png' },
      { name: 'Elasticsearch', image: 'images/technologies/elasticsearch.png' },
      { name: 'PostgreSQL', image: 'images/technologies/postgresql.png' },
      { name: 'Memcached', image: 'images/technologies/memcached.png' },
      { name: 'REST APIs', image: 'images/technologies/api.png' },
    ],
    fontAwesomeIcon: "fa-server"
  },
];

const Skills = () => {
  return (
    <div id="skills" className="skills-section">
      <h2 className="name">
        <span className="long-dash"></span>
        <span className="name-text">Skills</span>
      </h2>
      <div className="skills">
        {technologies.map((techCategory, index) => (
          <div className={techCategory.category.toLowerCase()}>
            <h3><i className={`fal ${techCategory.fontAwesomeIcon}`}></i><span>{techCategory.category}</span></h3>
            <ul>
              {techCategory.items.map((tech, idx) => (
                <li key={idx}>
                  <img src={`/${tech.image}`} alt={tech.name} />
                  <span>{tech.name}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Skills;