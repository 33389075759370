import React from 'react';

const About = () => {
  return (
    <div id="about-me" class="about-me">
      <h2 class="name">
        <span class="long-dash"></span>
        <span class="name-text">About me</span>
      </h2>
      <p>
        I'm a Software Engineer with a passion for turning ideas into reality through code. With skills in React, HTML, CSS, and JavaScript on the frontend, and Ruby on Rails, Elasticsearch, and PostgreSQL on the backend, I create web applications that offer intuitive user experiences and robust functionality.
      </p>
      <p>
        Check out my portfolio to see some of my favorite projects, and let's connect if you'd like to collaborate on something awesome together. I'm always excited to take on new challenges and put my skills to the test!
      </p>
    </div>
  )
}

export default About;